import { useFeatureIsOn } from "@growthbook/growthbook-react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import _without from "lodash/without";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import Draggable from "react-draggable";
import { shallowEqual, useSelector } from "react-redux";

import { Autocomplete } from "@kuva/ui-components";
import { SOURCE_TAGS } from "@kuva/ui-helpers";

import { AlarmTagsSelect } from "~/common/components/Dropdown";
import { selectIsEditing } from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import { flags } from "~/constants/feature-flags";
import { useAuthenticatedUser } from "~/hooks";

import { LeakRoiIcon } from "./LeakRoiIcon";
import { useStyles } from "./Styles";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

/** @function Dialog
  * The Create Dialog returns a draggable dialog that creates an alarm using specified start and end times.
  * 
    @param {bool} open: boolean that determines whether dialog is open or closed
    @param {string} title: header for the dialog box
    @param {func} onClose: function to be called when 'Cancel' button is pressed
    @param {string} alarmStart: string that represents start time of the alarm (cannot be greater than the end time)
    @param {func} handleAlarmStart: function that sets/changes the start time of the alarm
    @param {string} alarmEnd: string that represents end time of the alarm (cannot be smaller than the start time)
    @param {func} handleAlarmEnd: function that sets/changes the end time of the alarm
    @param {func} handleNewAlarm: function that creates the new alarm and routes to the alarm page
    @param {array} scans: array that contains scan results
  */
export const CreateAlarmDialog = ({
  setHumanActivityFocus,
  open,
  title,
  onClose,
  alarmStart,
  handleAlarmStart,
  alarmEnd,
  handleAlarmEnd,
  handleNewAlarm,
  scans,
  saveButtonRef,
  handleFullscreenImage,
  imgType,
  quantificationProps
}) => {
  const { isSuperuser, isReviewer } = useAuthenticatedUser();
  const showAlarmNotificationOption = isSuperuser || isReviewer;
  const {
    alarmLeakSources,
    leakDistance,
    setLeakDistance,
    selectedSource,
    setSelectedSource
  } = quantificationProps;

  const INIT_SOURCES = Object.values(SOURCE_TAGS)
    .map(source => source.name)
    .sort();

  const hasLeakRoi =
    alarmLeakSources?.leakOrigin?.length > 0 ||
    alarmLeakSources?.leakRois?.length > 0;

  const [humanActivity, setHumanActivity] = useState("");
  const isAlarmStateValid = alarmStart <= alarmEnd;
  const isHumanActivitySelected = humanActivity !== "";
  const isSaveEnabled =
    isAlarmStateValid && isHumanActivitySelected && selectedSource;
  const { classes } = useStyles({ alarmStart, alarmEnd, isAlarmStateValid });

  const isAlarmEditing = useSelector(selectIsEditing);
  const isLeakSourceHidden = useFeatureIsOn(
    flags.HIDE_LEAK_SOURCE_ON_NON_QUANT
  );
  const isAlarmCreationSelectTagsVisible = useFeatureIsOn(
    flags.ALARM_CREATION_SELECT_TAGS
  );

  const [disableNotification, setDisableNotification] = useState(false);
  const [selectedAlarmTags, setSelectedAlarmTags] = useState([]);

  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);

  const numberOfFrames = useMemo(() => {
    const frames = scans?.filter(
      frame => frame.createdOn >= alarmStart && frame.createdOn <= alarmEnd
    );
    return frames.length;
  }, [alarmStart, alarmEnd]);

  const alarm = {
    alarmStart,
    alarmEnd,
    numberOfFrames,
    disableNotification,
    humanActivity,
    tags: selectedAlarmTags,
    source: selectedSource,
    scans,
    distanceFromCamera: leakDistance ? parseFloat(leakDistance) : null
  };

  const menuProps = {
    anchorOrigin: {
      vertical: "bottom"
    }
  };

  const handleNotification = event => {
    setDisableNotification(event.target.checked);
  };

  const handleHumanActivityChange = event => {
    const isHumanActivity = event.target.value;
    setDisableNotification(isHumanActivity === "yes");
    setHumanActivity(isHumanActivity);
  };

  const handleChangeLeakDistance = e => {
    setLeakDistance(e.target.value);
  };

  const handleTagsSelectChange = event => {
    let filteredArray = event.target.value.filter(
      val => val !== "unselectable"
    );
    setSelectedAlarmTags(filteredArray);
  };

  const handleSelectedSourceChange = (_event, newValue) => {
    setSelectedSource(newValue);
    if (newValue === "Tanker Truck") {
      setDisableNotification(true);
      setHumanActivity("yes");
    } else {
      setDisableNotification(false);
      setHumanActivity("");
    }
  };

  const handleSelectedAlarmTagsDelete = (e, value) => {
    e.preventDefault();
    setSelectedAlarmTags(current => _without(current, value));
  };

  const handleClickCancel = () => {
    setHumanActivity("");
    onClose();
  };

  const handleClickSave = () => {
    handleNewAlarm(alarm)();
    setHumanActivity("");
    setSelectedSource(null);
    setLeakDistance(null);
  };

  const getTooltipMessage = () => {
    if (!isAlarmStateValid) {
      return alarmStart && alarmEnd
        ? "Start time cannot be greater than End time"
        : "Must specify Start and End times";
    } else if (!isHumanActivitySelected) {
      return "Please indicate if there is a person on site";
    } else if (!selectedSource) {
      return "Please select a source";
    }
  };

  return (
    <Dialog
      disableEnforceFocus
      hideBackdrop
      className={classes.dialog}
      PaperProps={{ style: { pointerEvents: "auto" } }}
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      sx={{
        "& .MuiDialog-paper": { backgroundImage: "unset" }
      }}
    >
      <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClickCancel}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContentButtons}>
          <Button disabled={isAlarmEditing} onClick={handleAlarmStart}>
            <div>
              <Typography className={classes.alarmStart}>
                {alarmStart ? alarmStart.substring(11, 19) : "SET START"}
              </Typography>
              <Typography variant="subtitle2"> SET START </Typography>
            </div>
          </Button>
          <Button disabled={isAlarmEditing} onClick={handleAlarmEnd}>
            <div className={classes.redBox}>
              <div className={classes.setEnd}>
                <Typography className={classes.alarmEnd}>
                  {alarmEnd ? alarmEnd.substring(11, 19) : "SET END"}
                </Typography>
                <Typography variant="subtitle2"> SET END </Typography>
              </div>
              {!isAlarmStateValid && (
                <div>
                  <div className={classes.warningBoxPointer} />
                  <Typography className={classes.warningBox}>
                    End time must be later than start time.
                  </Typography>
                </div>
              )}
            </div>
          </Button>
        </div>
        {isAlarmCreationSelectTagsVisible && (
          <div className={classes.tagsSelect}>
            <AlarmTagsSelect
              selectedAlarmTags={selectedAlarmTags}
              handleChange={handleTagsSelectChange}
              handleDelete={handleSelectedAlarmTagsDelete}
              menuProps={menuProps}
            />
          </div>
        )}
        {!isAlarmCreationSelectTagsVisible && (
          <Autocomplete
            label="Source *"
            variant="outlined"
            options={INIT_SOURCES}
            value={selectedSource}
            onChange={handleSelectedSourceChange}
          />
        )}
        {isLeakSourceHidden && !selectedCamera?.quantification ? null : (
          <div className={classes.quantificationSection}>
            <div>
              <TextField
                value={leakDistance}
                onChange={handleChangeLeakDistance}
                id="outlined-basic"
                label="Leak Source Distance"
                variant="outlined"
                className={classes.leakSourceDistance}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">m</InputAdornment>
                  )
                }}
              />
            </div>
            <div className={classes.leakSourceActionWrapper}>
              <FormLabel>Leak ROI</FormLabel>
              <IconButton
                onClick={() => handleFullscreenImage(imgType, false, true)}
                size="small"
              >
                <LeakRoiIcon hasLeakRoi={hasLeakRoi} />
              </IconButton>
            </div>
          </div>
        )}
        <div className={classes.dialogSection}>
          <FormControl
            variant="standard"
            onFocus={() => {
              setHumanActivityFocus(true);
            }}
            onBlur={() => {
              setHumanActivityFocus(false);
            }}
            component="fieldset"
          >
            <FormLabel required>Human Activity</FormLabel>
            <RadioGroup
              row
              aria-label="humanactivity"
              name="humanactivity"
              value={humanActivity}
              onChange={handleHumanActivityChange}
              className={classes.humanActivity}
              defaultValue="none"
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        {showAlarmNotificationOption && (
          <>
            <Divider variant="middle" />
            <div className={classes.dialogSection}>
              <FormControlLabel
                label={
                  <Typography className={classes.disableNotif}>
                    Disable Email Notification
                  </Typography>
                }
                control={
                  <Checkbox
                    disabled={humanActivity === "yes"}
                    checked={disableNotification}
                    onChange={handleNotification}
                    inputProps={{ "aria-label": "disable-notification" }}
                  />
                }
              />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {isSaveEnabled ? (
          <Button
            ref={saveButtonRef}
            color="primary"
            variant="outlined"
            onClick={handleClickSave}
            className={classes.saveButtonEnabled}
          >
            SAVE
          </Button>
        ) : (
          <Tooltip
            title={getTooltipMessage()}
            placement="top-start"
            arrow
            PopperProps={{
              modifiers: {
                offset: {
                  enabled: true,
                  offset: "0, -5px"
                },
                flip: {
                  enabled: false
                }
              }
            }}
          >
            <div>
              <Button
                disabled
                color="secondary"
                variant="outlined"
                className={classes.actionBtn}
              >
                SAVE
              </Button>
            </div>
          </Tooltip>
        )}
        <Button
          autoFocus
          onClick={handleClickCancel}
          color="primary"
          variant="outlined"
          className={classes.actionBtn}
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateAlarmDialog.propTypes = {
  setHumanActivityFocus: PropTypes.func,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  alarmStart: PropTypes.string,
  handleAlarmStart: PropTypes.func.isRequired,
  alarmEnd: PropTypes.string,
  handleAlarmEnd: PropTypes.func.isRequired,
  handleNewAlarm: PropTypes.func.isRequired,
  scans: PropTypes.array,
  saveButtonRef: PropTypes.shape({
    current: PropTypes.object
  }),
  handleFullscreenImage: PropTypes.func,
  imgType: PropTypes.string
};
