import CropFreeIcon from "@mui/icons-material/CropFree";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DownloadIcon from "@mui/icons-material/GetApp";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Dialog, Slider } from "@mui/material";
import clsx from "clsx";
import noop from "lodash/noop";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import { UnitNames, WindSpeedMultiplier } from "@kuva/units";

import { dateDisplays, DateTime } from "../core/DateTime";
import { LoadingSpinner } from "../core/LoadingSpinner";

import { ImageFrames, imageFrameTypes } from "./ImageFrames";
import { RadiantProgress } from "./RadiantProgress";
import SelectDownloadType from "./SelectDownloadType/SelectDownloadType";
import { SettingsMenuButton } from "./SettingsMenuButton";
import { ImagePlayerButton, useSliderStyles, useStyles } from "./styles";
import { WindCamera } from "./WindCamera";

const SEQUENCE_PLAYBACK_INTERVAL = 750;

const getSortedFrames = alarm =>
  orderBy(alarm?.frames, [frame => new Date(frame.createdOn)], ["asc"]);

export const cameraViews = {
  both: "RGB+SWIR",
  rgb: "RGB",
  swir: "SWIR"
};

/**
 * The ImagePlayer component displays a sequence of alarm frames as images in a
 * video-player-like interface. It provides playback controls to play/pause, and
 * a scrubber to navigate through the frames. It also allows the user to download
 * the sequence as a GIF and to view the sequence in fullscreen mode.
 *
 * The component receives an `alarm` object which should contain the frames to display,
 * a `camera` that provides information about the device that captured the frames, and
 * a `nameList` array that provides names for different orientations (Points of Interest).
 *
 * Each frame can be displayed in two formats: SWIR (Short-Wave Infrared) and RGB. If a
 * frame cannot be loaded, an error message is displayed instead of the image.
 */
export function ImagePlayer({
  alarm,
  camera,
  isFullscreen = false,
  loading = false,
  nameList,
  onDownloadAlarmGif,
  onCloseFullscreenClick = noop,
  singleImage = null,
  showCompass = false,
  isPlaying,
  setIsPlaying,
  frameIndex,
  setFrameIndex,
  setViewType,
  dateDisplay = null,
  timezone = null,
  isScan = false
}) {
  const containerRef = useRef(null);
  const playPauseButtonRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [imageErrors, setImageErrors] = useState({});
  const [isGifLoading, setIsGifLoading] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [cameraView, setCameraView] = useState(singleImage || cameraViews.both);
  const disablePlayback = !alarm?.frames || alarm?.frames?.length <= 1;
  const classes = useStyles({
    disablePlayback,
    isFullscreen,
    cameraView,
    loading
  });
  const sliderClasses = useSliderStyles({ disablePlayback });

  const { deviceId, poiOrientation } = alarm ?? {};
  const poiNameIdx = `${deviceId}-${poiOrientation}`;
  const PlaybackButtonIcon = isPlaying ? PauseIcon : PlayArrowIcon;
  const sortedFrames = getSortedFrames(alarm);
  const frame = sortedFrames[frameIndex];
  const windDirection =
    frame?.telemetry?.wind_direction || frame?.windDirection;
  const windSpeed = frame?.telemetry?.wind_speed || frame?.windSpeed;
  const imageFrameProps = {
    classes,
    sortedFrames,
    imageErrors,
    frameIndex,
    setImageErrors,
    handleImageError
  };

  const imageFramesComponentMap = {
    [cameraViews.swir]: (
      <ImageFrames
        type={isScan ? imageFrameTypes.scanSwir : imageFrameTypes.swir}
        {...imageFrameProps}
      />
    ),
    [cameraViews.rgb]: (
      <ImageFrames
        type={isScan ? imageFrameTypes.scanRgb : imageFrameTypes.rgb}
        {...imageFrameProps}
      />
    )
  };

  /**
   * Effect: Reset state when the alarm changes.
   */

  useEffect(() => {
    setFrameIndex(0);
    setIsGifLoading(false);
    setImageErrors({});
  }, [setFrameIndex, setIsGifLoading, setImageErrors, alarm]);

  /**
   * Effect: Play/pause the sequence when `isPlaying` state changes.
   */
  useEffect(() => {
    let timer;
    if (isPlaying) {
      timer = setTimeout(() => {
        setFrameIndex((frameIndex + 1) % alarm?.frames?.length);
      }, SEQUENCE_PLAYBACK_INTERVAL / playbackSpeed);
    }

    return () => clearTimeout(timer);
  }, [isPlaying, frameIndex, playbackSpeed, alarm, setFrameIndex]);

  function handleImageError(idx) {
    return () => {
      setImageErrors(prevState => {
        return { ...prevState, [idx]: true };
      });
    };
  }

  const handleContainerClick = () => {
    if (!disablePlayback) {
      playPauseButtonRef.current.click();
    }
  };

  const handleFullscreenClick = event => {
    event.stopPropagation();
    setIsPlaying(false);
    setFullscreen(true);
  };

  const handleCloseFullscreenClick = event => {
    event.stopPropagation();
    setFullscreen(false);
  };

  const handleFullscreenClose = event => {
    event.stopPropagation();
    setFullscreen(false);
  };

  const handleDownloadGifClick = (event, cameraViewType) => {
    event.stopPropagation();

    if (isGifLoading) return;

    setIsGifLoading(true);

    // Support both Promises and normal callback functions
    const result = onDownloadAlarmGif(
      cameraViewType || cameraView.toLowerCase()
    );
    if (result && typeof result.then === "function") {
      result.finally(() => setIsGifLoading(false));
    } else {
      setIsGifLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={classes.root}>
        {/* Use empty frames to keep side-by-side 3/4 aspect ratio sizing */}
        <div className={classes.frame} />
        <div className={classes.frame} />
        <div className={classes.loading}>
          <LoadingSpinner centered />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={classes.root}
        ref={containerRef}
        onClick={handleContainerClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={classes.framesContainer} data-testid="image-player">
          {cameraView === cameraViews.both ? (
            <>
              {imageFramesComponentMap[cameraViews.swir]}
              {imageFramesComponentMap[cameraViews.rgb]}
            </>
          ) : (
            imageFramesComponentMap[cameraView]
          )}
        </div>

        <div className={classes.overlay}>
          <div className={classes.bars}>
            <div
              className={clsx(classes.overlayBar, classes.topBar, {
                [classes.showBar]: isHovered
              })}
            >
              <div className={classes.headerDiv}>
                <div>
                  <div className={classes.cameraName} data-testid="camera-name">
                    {camera?.name || camera?.id}
                  </div>
                  {!!poiOrientation && (
                    <div className={classes.poiLabel}>
                      <span data-testid="poi-orientation">
                        {nameList[poiNameIdx]?.name ?? ` ${poiOrientation}°`}
                      </span>
                    </div>
                  )}
                </div>
                <div className={classes.timestamp}>
                  {camera && sortedFrames[frameIndex]?.createdOn && (
                    <DateTime
                      timezone={timezone ?? camera?.timezone}
                      date={new Date(sortedFrames[frameIndex]?.createdOn)}
                      display={dateDisplay ?? dateDisplays.CAMERA}
                      format="MMMM dd, yyyy, hh:mm:ss a (zz)"
                      data-testid="event-date"
                    />
                  )}
                </div>

                {onDownloadAlarmGif && (
                  <div>
                    {cameraView === cameraViews.both ? (
                      <ImagePlayerButton
                        size="small"
                        data-testid="download-button-both-views"
                      >
                        {isGifLoading ? (
                          <LoadingSpinner size={24} />
                        ) : (
                          <SelectDownloadType
                            handleDownloadGifClick={handleDownloadGifClick}
                            useIcon
                          />
                        )}
                      </ImagePlayerButton>
                    ) : (
                      <ImagePlayerButton
                        size="small"
                        onClick={handleDownloadGifClick}
                        data-testid="download-button-single-view"
                      >
                        {isGifLoading ? (
                          <LoadingSpinner size={24} />
                        ) : (
                          <DownloadIcon />
                        )}
                      </ImagePlayerButton>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div
              className={clsx(classes.overlayBar, {
                [classes.showBar]: isHovered
              })}
            >
              {showCompass && (
                <div
                  className={classes.cameraCompassWrapper}
                  data-testid="compass"
                >
                  <WindCamera windDirection={windDirection} />
                  <RadiantProgress
                    value={
                      windSpeed * WindSpeedMultiplier[UnitNames.MILES_PER_HOUR]
                    }
                  />
                </div>
              )}
              <div className={classes.bottomBar}>
                <ImagePlayerButton
                  ref={playPauseButtonRef}
                  size="small"
                  onClick={() => setIsPlaying(!isPlaying)}
                  className={classes.playPauseButton}
                  disabled={disablePlayback}
                  data-testid="play-pause-button"
                >
                  {disablePlayback ? (
                    <CropOriginalIcon />
                  ) : (
                    <PlaybackButtonIcon />
                  )}
                </ImagePlayerButton>
                <Slider
                  classes={sliderClasses}
                  max={alarm?.frames?.length - 1 || 0}
                  min={0}
                  step={1}
                  defaultValue={0}
                  value={frameIndex}
                  onChange={(e, value) => setFrameIndex(value)}
                  onClick={e => e.stopPropagation()}
                  disabled={disablePlayback}
                  data-testid="slider"
                  size="small"
                />
                <SettingsMenuButton
                  disablePlayback={disablePlayback}
                  playbackSpeed={playbackSpeed}
                  setPlaybackSpeed={setPlaybackSpeed}
                  cameraView={cameraView}
                  setCameraView={setCameraView}
                  setViewType={setViewType}
                  cameraViews={cameraViews}
                />

                {isFullscreen ? (
                  <ImagePlayerButton
                    size="small"
                    onClick={onCloseFullscreenClick}
                    data-testid="exit-fullscreen-button"
                  >
                    <FullscreenExitIcon />
                  </ImagePlayerButton>
                ) : (
                  <ImagePlayerButton
                    size="small"
                    onClick={handleFullscreenClick}
                    data-testid="open-fullscreen-button"
                  >
                    <CropFreeIcon />
                  </ImagePlayerButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        classes={{ paper: classes.dialogPaper }}
        onClose={handleFullscreenClose}
        open={fullscreen}
      >
        <div className={classes.fullscreenDialogContent}>
          {/**
           * The fullscreen mode of the player recursively renders the component to
           * avoid duplicating the player code. Since the component is responsive, we
           * make the dialog fullscreen and the inner component will adapt accordingly.
           */}
          <ImagePlayer
            isFullscreen={fullscreen}
            camera={camera}
            alarm={alarm}
            nameList={nameList}
            onCloseFullscreenClick={handleCloseFullscreenClick}
            onDownloadAlarmGif={onDownloadAlarmGif}
            initialFrameIndex={frameIndex}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            frameIndex={frameIndex}
            setFrameIndex={setFrameIndex}
            isScan={isScan}
          />
        </div>
      </Dialog>
    </>
  );
}

ImagePlayer.propTypes = {
  alarm: PropTypes.object,
  allowFullscreen: PropTypes.bool,
  camera: PropTypes.object,
  initialFrameIndex: PropTypes.number,
  isFullscreen: PropTypes.bool,
  loading: PropTypes.bool,
  nameList: PropTypes.object,
  onCloseFullscreenClick: PropTypes.func,
  onDownloadAlarmGif: PropTypes.func,
  singleImage: PropTypes.string,
  showCompass: PropTypes.bool,
  isPlaying: PropTypes.bool.isRequired,
  setIsPlaying: PropTypes.func.isRequired,
  frameIndex: PropTypes.number.isRequired,
  setFrameIndex: PropTypes.func.isRequired,
  dateDisplay: PropTypes.string,
  timezone: PropTypes.string,
  setViewType: PropTypes.func,
  isScan: PropTypes.bool
};
