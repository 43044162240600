import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";

import { Autocomplete } from "@kuva/ui-components";
import { SOURCE_TAGS } from "@kuva/ui-helpers";

import { useQuantification } from "~/hooks";

import InformationItem from "./InformationItem";
import { LabelInputWrapper } from "./styled-components";

const AlarmQuantificationInfo = ({
  alarm,
  selectedSource,
  handleSelectedSourceChange
}) => {
  const quantificationData = useQuantification(alarm);
  const { distance, quantificationResult } = quantificationData || {};

  const INIT_SOURCES = Object.values(SOURCE_TAGS).map(source => source.name);

  return (
    <Stack spacing={1}>
      <LabelInputWrapper>
        <Typography variant="body2" sx={{ color: "#737373" }}>
          Source:
        </Typography>
        <Autocomplete
          variant="outlined"
          options={INIT_SOURCES}
          value={selectedSource}
          sx={{ width: "150px" }}
          onChange={handleSelectedSourceChange}
        />
      </LabelInputWrapper>
      <InformationItem label="Leak Source Distance" value={distance} />
      <InformationItem label="Estimated Size" value={quantificationResult} />
    </Stack>
  );
};

AlarmQuantificationInfo.propTypes = {
  alarm: PropTypes.object,
  selectedSource: PropTypes.string,
  handleSelectedSourceChange: PropTypes.func.isRequired
};

export default AlarmQuantificationInfo;
